import axios from 'axios'
import { getAuthToken } from '@/utils/auth'
// export const baseUrl = 'http://106.14.146.157:8909/shangdong-skin/'; // 测试
export const baseUrl = 'http://sp.online.umersoft.com/shangdong-skin/' // 正式
// export const baseUrl = 'http://sp.online.umersoft.com:8909/shangdong-skin/'

// export const baseUrl ='https://spycbl.umersoft.com'
const axiosInstance = axios.create({
  baseURL: baseUrl,
  // headers: {
  //   token: getAuthToken()
  // },
})
axiosInstance.interceptors.request.use(
  config => {
    const token = getAuthToken(); //获取存储在本地的token
    //   config.headers = {};
    if (token) {
      config.headers.token = token; //携带权限参数

    }
    return config;
  },
  err => {
    console.log(err, '网络错误')
  }
);
axiosInstance.interceptors.response.use(
  (res) => {
    console.log('拦截器：', res.data)

    const data = res.data
    // if (data.code > 0) {
    if (data.code == 200) {
      return data.data
    } else {
      return data
    }
  },
  (err) => {
    console.log(err, '网络错误')
  }
)

export { axiosInstance }
